import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appSlice from './features/app/appSlice';
import fileSlice from './features/file/fileSlice';
import orderSlice from './features/order/orderSlice';
import teamSlice from './features/team/teamSlice';
import userSlice from './features/user/userSlice';

const appPersistConfig = {
  key: 'app',
  storage,
  whitelist: ['shops'],
  blacklist: [],
};

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['currentUser', 'userType'],
  blacklist: [],
};

const filePersistConfig = {
  key: 'file',
  storage,
  whitelist: ['uploadedFiles'],
  blacklist: [],
};

const orderPersistConfig = {
  key: 'order',
  storage,
  whitelist: [''],
  blacklist: [],
};

const teamPersistConfig = {
  key: 'team',
  storage,
  whitelist: ['teamInfo', 'billingDetails'],
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appSlice),
  user: persistReducer(userPersistConfig, userSlice),
  file: persistReducer(filePersistConfig, fileSlice),
  order: persistReducer(orderPersistConfig, orderSlice),
  team: persistReducer(teamPersistConfig, teamSlice),
});

export default rootReducer;
