import '../../assets/fonts/NotoSerif-Regular.ttf'; // for Noto Serif font
import { createTheme } from '@mui/material/styles';
import '../fonts.css';

const fontFamilyLato = 'Lato, Arial, sans-serif';
const fontFamilyNotoSerif = 'Noto Serif, serif';
const fontWeightRegular = 400;
const fontWeightMedium = 500;
const fontWeightSemibold = 600;
const fontWeightBold = 700;
const colorPrimary = '#4A4A4A';
const colorSecondary = '#757575';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    mainButton: React.CSSProperties;
    ctaButton: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
    mainButton?: React.CSSProperties;
    ctaButton?: React.CSSProperties;
  }

  interface Palette {
    customColor?: Palette['primary'];
  }
  interface PaletteOptions {
    customColor?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    body5: true;
    mainButton: true;
    ctaButton: true;
  }
}

export const printtTheme = createTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 320,
      sm: 750,
      md: 970,
      lg: 1170,
      xl: 2000,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          overflow: 'visible', // Allow scaled content to be visible
          position: 'relative', // Ensure proper stacking context
          zIndex: 1,
        },
        maxWidthXl: {
          overflow: 'visible',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
          fontFamily: fontFamilyLato,
          fontWeight: fontWeightRegular,
          color: colorPrimary,
        },
        /* Global CSS Reset */
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },
        'body, h1, h2, h3, h4, h5, h6, p, ol, ul': {
          margin: 0,
          padding: 0,
          overflowX: 'hidden',
        },
        'ol, ul': {
          listStyle: 'none',
        },
        a: {
          textDecoration: 'none',
          color: 'inherit',
        },
        'button, input, select, textarea': {
          appearance: 'none',
          border: 'none',
          background: 'none',
          outline: 'none',
        },
        iframe: {
          zIndex: 9999,
        },
        '::-webkit-scrollbar': {
          width: '10px',
        },
        '::-webkit-scrollbar-track': {
          background: '#05b59c',
          borderRadius: '20%',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '10px',
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
        '*': {
          scrollbarWidth: 'thin',
          scrollbarColor: '#05b59c #f1f1f1',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
        toolbar: {
          height: '56px',
          minHeight: '56px',
          '@media (max-width: 600px)': {
            height: '48px',
            minHeight: '48px',
          },
        },
        select: {
          fontSize: '14px',
        },
        selectIcon: {
          top: 'calc(50% - 12px)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '100px',
          borderRadius: '6px',
          border: '1px solid #05B59C',
          cursor: 'pointer',
          backgroundColor: 'transparent',
        },
        text: {
          cursor: 'pointer',
          border: 'none',
          color: '#05B59C',
        },
        containedPrimary: {
          minWidth: '200px',
          color: '#FFFFFF !important',
          border: 'none',
          backgroundColor: '#05B59C',
          '&:hover': {
            backgroundColor: '#04A089',
          },
        },
        contained: {
          color: '#FFFFFF',
          backgroundColor: '#212121',
          '&:hover': {
            opacity: '0.9',
            backgroundColor: '#212121',
          },
          '&:focus': {
            boxShadow: '0 0 0 0.2rem rgb(0 0 0 / 20%)',
          },
        },
        outlined: {
          minWidth: '200px',
          color: '#212121',
          borderColor: '#212121',
          backgroundColor: '#ffffff',
          '&:hover': {
            opacity: '0.9',
            backgroundColor: '#CDF0EB',
            color: '#ffffff !important',
          },

          '&:focus': {
            boxShadow: '0 0 0 0.2rem rgb(0 0 0 / 20%)',
            backgroundColor: '#CDF0EB',
            color: '#ffffff !important',
          },
        },
        outlinedPrimary: {
          borderColor: '#05B59C', // Match the border color to the primary color
          color: '#05B59C',
          '&:hover': {
            borderColor: '#04A089', // Match the hover border color to the primary dark color
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&$error': {
            fontSize: '12px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: '120px !important',
        },
        input: {
          fontSize: '16px',
          fontFamily: 'Lato',
          fontWeight: 400,
          color: '#212121', // black
          borderColor: '#E0E0E0',
          height: 'auto',
          backgroundColor: '#E8F0FE', // Default background color
          '&.Mui-disabled': {
            color: '#A9A9A9', // Replace with your $darkGrey100Color
          },
          '&:not(:placeholder-shown)': {
            backgroundColor: 'transparent', // Transparent background when data is filled
          },
          '&.Mui-focused': {
            borderColor: '#0DAB95', // Focus border color
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '5px !important',
          backgroundColor: '#e0e0e0 !important',
          borderRadius: '3px',
        },
        colorPrimary: {
          backgroundColor: '#e0e0e0 !important',
          borderRadius: '3px',
        },
        bar: {
          height: '5px !important',
          borderRadius: '5px',
          backgroundColor: '#212121', // Replace with your $black color
        },
        barColorPrimary: {
          backgroundColor: '#05B59C', // Primary color for the bar
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: '0 !important',
          '&:hover $notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)', // Change as needed
          },
          '&$focused $notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)', // Change as needed
          },
        },
        notchedOutline: {
          borderColor: 'rgba(0, 0, 0, 0.23)', // Change as needed
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: 'pink',
          },
          color: 'black',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          marginLeft: '5px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {},
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#cdf0eb !important',
            color: '#959595',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#D3D3D3 !important', // Replace with your $lightGrey100Color
          display: 'none !important',
          '&.MuiStepIcon-active': {
            borderRadius: '50%',
          },
          '&.Mui-completed': {
            color: '#32CD32 !important', // Replace with your $green100Color
          },
        },
        text: {
          fontSize: '0.85rem !important',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        alternativeLabel: {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '4px 16px !important',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '0 16px 0 0 !important',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          minHeight: '210px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(1) !important',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingRight: '0',
          '&:focus': {
            backgroundColor: '#FFFFFF !important', // Replace with your $white color
          },
        },
        iconOutlined: {
          right: '3px !important',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          borderRadius: '10px !important',
          '&.expanded': {
            borderRadius: '10px 10px 0 0 !important',
          },
        },
        message: {
          width: '100%',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '10px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          width: '100%',
          margin: '0 auto',
        },
        paper: {
          padding: '1rem 0',
          margin: '0 !important',
          '& .MuiDialogActions-root': {
            padding: '0',
            marginTop: '2rem',
          },
        },
        paperFullWidth: {
          backgroundColor: '#F8F8F8 !important', // Replace with your $alabaster color
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          minHeight: '68px',
          borderRadius: '0',
          '&::before': {
            backgroundColor: 'transparent',
          },
          boxShadow: 'none',
          borderBottom: '1px solid #E0E0E0',
          marginBottom: '10px',
        },
        rounded: {
          borderRadius: '10px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0',
          minHeight: '68px',
        },
        content: {
          margin: '0',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '10px 0 !important',
          '&.popover': {
            padding: '0 10px !important',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: '100%',
          marginLeft: '0 !important',
          marginRight: '0 !important',
          '&.active': {
            backgroundColor: '#CDF0EB', // Replace with your $green50Color
          },
        },
        label: {
          width: '100%',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          justifyContent: 'center',
        },
      },
    },
  },
  palette: {
    common: {
      black: '#212121', // $black
      white: '#FFFFFF', // $white
    },
    primary: {
      light: '#CDF0EB', // $lightGreen100Color
      main: '#05B59C', // $green100Color
      dark: '#05B59C', // $green100Color
      contrastText: '#FFFFFF', // $white
    },
    secondary: {
      light: '#FDD9D9', // Assuming a light red color
      main: '#F44242', // $red100Color
      dark: '#F44242', // $red100Color
      contrastText: '#FFFFFF', // $white
    },
    error: {
      light: '#FDD9D9', // Assuming a light red color
      main: '#F44242', // $red100Color
      dark: '#F44242', // $red100Color
      contrastText: '#FFFFFF', // $white
    },
    warning: {
      light: '#FFEED6', // Assuming a light yellow color
      main: '#FFBC5B', // $yellow100Color
      dark: '#FFBC5B', // $yellow100Color
      contrastText: '#212121', // $black
    },
    info: {
      light: '#D1E8F0', // Assuming a light blue color
      main: '#188EB3', // $blue100Color
      dark: '#188EB3', // $blue100Color
      contrastText: '#FFFFFF', // $white
    },
    success: {
      light: '#CDF0EB', // $lightGreen100Color
      main: '#05B59C', // $green100Color
      dark: '#05B59C', // $green100Color
      contrastText: '#FFFFFF', // $white
    },
    text: {
      primary: '#4A4A4A', // Assuming a dark grey color
      secondary: '#757575', // $darkGrey100Color
      disabled: '#BDBDBD', // $grey100Color
    },
    grey: {
      A100: '#F9F9F9', // $alabaster
      A200: '#E0E0E0', // $lightGrey100Color
      A400: '#BDBDBD', // $grey100Color
      A700: '#757575', // $darkGrey100Color
    },
    divider: '#E0E0E0', // $lightGrey100Color
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    h1: {
      fontFamily: 'Noto Serif, serif',
      fontWeight: fontWeightRegular,
      color: colorPrimary,
      fontSize: '1.75rem', // 28px
      letterSpacing: '-0.39px',
      lineHeight: '38px',
      '@media (max-width: 767px)': {
        fontSize: '1.25rem',
      },
    },
    h2: {
      fontFamily: fontFamilyLato,
      fontWeight: fontWeightSemibold,
      fontSize: '1.25rem', // 20px
      letterSpacing: '-0.5px',
      color: colorPrimary,
      '@media (max-width: 767px)': {
        fontSize: '1rem', // 16px
      },
    },
    h3: {
      fontFamily: 'Noto Serif, serif',
      fontWeight: fontWeightRegular,
      fontSize: '1.25rem', // 20px
      color: '#4A4A4A',
      letterSpacing: '-0.27px',
      lineHeight: '32px',
      '@media (max-width: 767px)': {
        fontSize: '1.125rem', // 18px
      },
    },
    h4: {
      fontFamily: 'Noto Serif, serif',
      fontWeight: fontWeightBold,
      fontSize: '1rem', // 16px
      letterSpacing: '-0.5px',
      color: '#4A4A4A',
    },
    body1: {
      fontFamily: fontFamilyLato,
      fontWeight: fontWeightSemibold,
      fontSize: '1rem', // 16px
      letterSpacing: '-0.27px',
      lineHeight: '32px',
      color: '#4A4A4A',
    },
    body2: {
      fontFamily: fontFamilyLato,
      fontWeight: fontWeightMedium,
      fontSize: '1rem',
      letterSpacing: '-0.27px',
      lineHeight: '20px',
    },
    body3: {
      fontFamily: fontFamilyLato,
      fontWeight: fontWeightSemibold,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.4,
      letterSpacing: '-0.25px',
    },
    body4: {
      fontFamily: fontFamilyLato,
      fontWeight: fontWeightMedium,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.4,
      letterSpacing: '-0.25px',
      color: '#757575',
    },
    body5: {
      fontFamily: fontFamilyLato,
      fontWeight: fontWeightMedium,
      fontSize: '0.75rem', // 12px
      lineHeight: 1.4,
      letterSpacing: '-0.25px',
    },
    mainButton: {
      fontFamily: fontFamilyLato,
      fontWeight: fontWeightBold,
      fontSize: '1.125rem', // 18px
      lineHeight: 1.9,
      letterSpacing: '-0.25px',
      textTransform: 'none',
    },
    ctaButton: {
      fontFamily: fontFamilyLato,
      fontWeight: fontWeightBold,
      fontSize: '2rem', // 32px
      lineHeight: 1.9,
      letterSpacing: '-0.25px',
      textTransform: 'none',
      [createTheme().breakpoints.down('sm')]: {
        fontSize: '1.625',
      },
    },
  },
});
