const dev = {
  url: process.env.REACT_APP_DEV_URL,
  api_endpoint: process.env.REACT_APP_DEV_API_ENDPOINT,
  client_id: process.env.REACT_APP_DEV_CLIENT_ID,
  client_secret: process.env.REACT_APP_DEV_CLIENT_SECRET,
  facebook_app_id: process.env.REACT_APP_DEV_FACEBOOK_APP_ID,
  pusher_app_id: process.env.REACT_APP_DEV_PUSHER_APP_ID,
  pusher_secret: process.env.REACT_APP_DEV_PUSHER_SECRET,
  pusher_key: process.env.REACT_APP_DEV_PUSHER_KEY,
  pusher_cluster: process.env.REACT_APP_DEV_PUSHER_CLUSTER,
  stripe_pk: process.env.REACT_APP_DEV_STRIPE_PK,
  stripe_pk_printnow: process.env.REACT_APP_DEV_STRIPE_PK_PRINTNOW,
  google_client_id: process.env.REACT_APP_DEV_GOOGLE_CLIENT_ID,
  apple_identifier: process.env.REACT_APP_DEV_APPLE_IDENTIFIER,
  apple_redirect_uri: process.env.REACT_APP_DEV_APPLE_REDIRECT,
};

const prod = {
  url: process.env.REACT_APP_PROD_URL,
  api_endpoint: process.env.REACT_APP_PROD_API_ENDPOINT,
  client_id: process.env.REACT_APP_PROD_CLIENT_ID,
  client_secret: process.env.REACT_APP_PROD_CLIENT_SECRET,
  facebook_app_id: process.env.REACT_APP_PROD_FACEBOOK_APP_ID,
  pusher_app_id: process.env.REACT_APP_PROD_PUSHER_APP_ID,
  pusher_secret: process.env.REACT_APP_PROD_PUSHER_SECRET,
  pusher_key: process.env.REACT_APP_PROD_PUSHER_KEY,
  pusher_cluster: process.env.REACT_APP_PROD_PUSHER_CLUSTER,
  stripe_pk: process.env.REACT_APP_PROD_STRIPE_PK,
  stripe_pk_printnow: process.env.REACT_APP_PROD_STRIPE_PK_PRINTNOW,
  google_client_id: process.env.REACT_APP_PROD_GOOGLE_CLIENT_ID,
  apple_identifier: process.env.REACT_APP_PROD_APPLE_IDENTIFIER,
  apple_redirect_uri: process.env.REACT_APP_PROD_APPLE_REDIRECT,
};

export const settings = process.env.REACT_APP_NODE_ENV === 'production' ? prod : dev;
export const sentry_dsn = process.env.REACT_APP_SENTRY_DSN;
export const intercome_id = process.env.REACT_APP_INTERCOME_ID;
export const mapbox_token = process.env.REACT_APP_MAPBOX_TOKEN;
export const token = `Bearer ${btoa(`${settings.client_id}:${settings.client_secret}`)}`;
export const endpointAuth = `Basic ${btoa(`${settings.client_id}:${settings.client_secret}`)}`;
export const EuropeBboxCoordinates = [-17.8600260412, 37.4540302955, 41.7491235769, 61.6269397952];
export const UkAddressesApiKey = 'cyG2BHk03EqZFnWNTtwNKQ31653';
export const base = settings.api_endpoint;
export const api_version = '/v5/api/';
export const loginUrl = base + api_version + 'tokens/create/';
export const logoutUrl = base + api_version + 'tokens/revoke/';
export const tokenRefreshUrl = base + api_version + 'tokens/refresh/';
export const registerUrl = base + api_version + 'users/';
export const loginGoogleUrl = base + api_version + 'google/social/';
export const loginAppleMobile = base + api_version + 'apple/social/';
export const loginAppleUrl = base + api_version + 'apple/web/social/';
export const resetPasswordUrl = base + api_version + 'password/reset/';
export const userUrl = base + api_version + 'users';
export const filesUrl = base + api_version + 'documents/';
export const previewPdfUrl = base + api_version + 'preview-document/';
export const addressUrl = base + api_version + 'address/';
export const cc_ordersUrl = base + '/orders/';
export const deliveryAndSelf_orderUrl = base + api_version + 'orders/';
export const servicesUrl = base + '/services';
export const collectLocationsUrl = base + '/collect/locations';
export const pricesUrl = base + '/prices/';
export const printersCodeUrl = base + api_version + 'printers/code';
export const stripePaymentIntentUrl = base + api_version + 'payment-intent/';
export const printersLocationsUrl = base + api_version + 'locations/?location_type=self_service';
export const shopsLocationsUrl = base + api_version + 'locations/?location_type=click_and_collect';

export const teamsDetailsUrl = base + '/teams/';
export const teamsInvitationsUrl = base + '/teams/invitations/';
export const teamsMembersListUrl = base + '/teams/members/';
export const teamsOrdersListUrl = base + '/teams/orders/';
export const teamsBillingUrl = base + '/teams/billing/';
export const teamsInvoicesUrl = base + '/teams/invoices/';
export const teamsAnalyticsUrl = base + '/teams/analytics/';
export const authorizeTeamCard = base + api_version + 'auth/payment-intent/';
export const limitsUrl = base + '/teams/spending/';
export const listPaymentMethodsUrl = base + '/teams/list-payment-methods/';
export const checkEmailExistUrl = base + api_version + 'users/email-check/';
export const locationUrl = `https://ipapi.co/json/?key=${process.env.REACT_APP_IP_API_KEY}`;
