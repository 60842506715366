import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies, { get } from 'js-cookie';
import i18n from 'i18next';
import {
  checkEmailExistUrl,
  loginAppleMobile,
  loginAppleUrl,
  loginGoogleUrl,
  loginUrl,
  logoutUrl,
  registerUrl,
  resetPasswordUrl,
  settings,
  token,
  userUrl,
} from '../../../settings';
import { sendRequest } from '../../../store/api';
import { getStateAndToken } from '../../../utils/helpers';
import { PasswordType, ProfileType } from '../../../pages/Profile/types/types';
import { CreateUserType, GuestUser, LoginUserType } from './userTypes';
import { clearUserSlice, setIsNewUser } from './userSlice';
import { clearAppSlice, setSnackbar } from '../app/appSlice';
import { clearOrderSlice } from '../order/orderSlice';
import { clearTeamSlice } from '../team/teamSlice';
import { clearFileSlice } from '../file/fileSlice';
let isGuestUserCreationInProgress = false;
let isGetUserCreationInProgress = false;
export const checkEmailExist = createAsyncThunk('user/checkEmail', async ({ email }: { email: string }, thunkApi) => {
  try {
    const { data, status } = await sendRequest('post', checkEmailExistUrl, { email }, token);
    return { data, status };
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    thunkApi.dispatch(
      setSnackbar({ open: true, message: i18n.t('error.UserAlreadyExists', { email }), type: 'error' }),
    );
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const createGuestUser = createAsyncThunk('user/createGuest', async (_, thunkApi) => {
  if (isGuestUserCreationInProgress) {
    return;
  }

  isGuestUserCreationInProgress = true;
  try {
    const guest_user = Cookies.get('guest_user');

    let userData = null;

    const body: GuestUser = {
      email: `${guest_user}@guest.printt`,
      password: `${guest_user}`,
      first_name: 'Guest',
      last_name: 'User',
      is_guest: true,
      country: i18n.language === 'bg' ? 'BG' : 'UK',
    };

    await thunkApi.dispatch(checkEmailExist({ email: body.email })).then(async (res: any) => {
      const { data, status } = res.payload;

      const { is_guest, exist_in_db, is_team_member } = data;
      if (is_guest && !exist_in_db) {
        const { data, status } = await sendRequest('post', registerUrl, body, token);
        thunkApi.dispatch(setIsNewUser(true));
        userData = data;
      } else {
        const { data, status } = await sendRequest(
          'post',
          loginUrl,
          { email: body?.email, password: body?.password },
          token,
        );
        thunkApi.dispatch(setIsNewUser(false));
        userData = data;
      }
    });

    return userData;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const registerUser = createAsyncThunk('user/create', async (formData: CreateUserType, thunkApi) => {
  try {
    const body = {
      email: `${formData?.email}`,
      password: formData?.password,
      first_name: formData?.firstName,
      last_name: formData?.lastName,
      phone_number: formData?.phoneNumber,
      isGuest: formData?.is_guest,
      country: formData?.country,
    };

    const { data, status } = await sendRequest('post', registerUrl, body, token);
    if (status === 200) {
      const loginData = {
        email: formData?.email || '',
        password: formData?.password || '',
      };
      thunkApi.dispatch(loginUser(loginData)).then((res: any) => {
        thunkApi.dispatch(getUser());
      });
    }
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;

    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t(errorMessage), type: 'error' }));
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const loginGoogle = createAsyncThunk('user/loginGoogle', async (code: string, thunkApi) => {
  try {
    const { data } = await sendRequest('post', loginGoogleUrl, { code: code }, token);

    await thunkApi.dispatch(checkEmailExist({ email: data.email })).then(async (res: any) => {
      if (res.payload.status === 204) {
        thunkApi.dispatch(setIsNewUser(true));
      } else {
        thunkApi.dispatch(setIsNewUser(false));
      }
    });
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('login.successfulLogin'), type: 'success' }));
    return data;
  } catch (error: any) {
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('login.loginFailed'), type: 'error' }));
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const appleLogin = createAsyncThunk('user/appleLogin', async (loginResponse: any, thunkApi) => {
  try {
    const { data } = await sendRequest(
      'post',
      window.isMobileApp ? loginAppleMobile : loginAppleUrl,
      { token: loginResponse.authorization.id_token },
      token,
    );
    await thunkApi.dispatch(checkEmailExist({ email: data.email })).then(async (res: any) => {
      if (res.payload.status === 204) {
        thunkApi.dispatch(setIsNewUser(true));
      } else {
        thunkApi.dispatch(setIsNewUser(false));
      }
    });
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('login.successfulLogin'), type: 'success' }));
    return data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const loginUser = createAsyncThunk('user/login', async (formData: LoginUserType, thunkApi) => {
  try {
    const body = {
      email: formData.email,
      password: formData.password,
    };
    await thunkApi.dispatch(checkEmailExist({ email: body.email })).then(async (res: any) => {
      if (res.payload.status === 204) {
        thunkApi.dispatch(setIsNewUser(true));
      } else {
        thunkApi.dispatch(setIsNewUser(false));
      }
    });

    const { data, status } = await sendRequest('post', loginUrl, body, token);
    if (status === 201) {
      await thunkApi.dispatch(getUser());
    }
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('login.successfulLogin'), type: 'success' }));
    return data;
  } catch (error: any) {
    const errorMessage = error.response.data.detail;
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('error.wrongCredentials'), type: 'error' }));
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const logoutUser = createAsyncThunk('user/logout', async (_, thunkApi) => {
  isGuestUserCreationInProgress = false;
  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { data } = await sendRequest(
      'post',
      logoutUrl,
      { client_id: settings.client_id, token },
      `Bearer ${access_token}`,
    );
    thunkApi.dispatch(clearAppSlice());
    thunkApi.dispatch(clearOrderSlice());
    thunkApi.dispatch(clearFileSlice());
    thunkApi.dispatch(clearTeamSlice());
    thunkApi.dispatch(clearUserSlice());
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('messages.userLogoutSuccess'), type: 'success' }));
    thunkApi.dispatch(createGuestUser());

    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getUser = createAsyncThunk('user/currentUser', async (_, thunkApi) => {
  if (isGetUserCreationInProgress) {
    return;
  }

  isGetUserCreationInProgress = true;

  try {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { user_id } = state.user.currentUser;

    const { data } = await sendRequest('get', `${userUrl}/${user_id}`, null, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  } finally {
    isGetUserCreationInProgress = false;
  }
});

export const updateUser = createAsyncThunk('user/updateUser', async (formData: ProfileType, thunkApi) => {
  const [state, access_token] = getStateAndToken(thunkApi);
  const { user_id } = state.user.currentUser;

  const body = {
    first_name: formData.first_name,
    last_name: formData.last_name,
    country: formData.country,
    phone_number: formData.phone_number,
  };

  try {
    const { data, status } = await sendRequest('put', `${userUrl}/${user_id}`, body, `Bearer ${access_token}`);
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('messages.profileUpdatedSuccess'), type: 'success' }));

    return data;
  } catch (error: any) {
    const errorMessage = error.response.data.detail;
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('messages.userUpdateFailure'), type: 'error' }));
    return thunkApi.rejectWithValue(errorMessage);
  }
});
export const updateUserPassword = createAsyncThunk(
  'user/updateUserPassword',
  async (formData: PasswordType, thunkApi) => {
    const [state, access_token] = getStateAndToken(thunkApi);
    const { user_id } = state.user.currentUser;

    const body = {
      current_password: formData.current_password,
      new_password: formData.password,
      confirm_new_password: formData.confirmPassword,
    };

    try {
      const { data, status } = await sendRequest('put', `${userUrl}/${user_id}`, body, `Bearer ${access_token}`);
      thunkApi.dispatch(
        setSnackbar({ open: true, message: i18n.t('messages.passwordUpdatedSuccess'), type: 'success' }),
      );

      return data;
    } catch (error: any) {
      const errorMessage = error.response.data.detail;
      thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('messages.userUpdateFailure'), type: 'error' }));
      return thunkApi.rejectWithValue(errorMessage);
    }
  },
);

export const deleteUser = createAsyncThunk('user/updateUser', async (_, thunkApi) => {
  const [state, access_token] = getStateAndToken(thunkApi);
  const { currentUser } = state.user;

  try {
    const { data } = await sendRequest('delete', `${userUrl}/${currentUser?.user_id}/`, null, `Bearer ${access_token}`);
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('service.deleteAccount'), type: 'success' }));
    return data;
  } catch (error: any) {
    const errorMessage = error.response.data.detail;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const resetUserPassword = createAsyncThunk('user/updateUser', async ({ email }: { email: string }, thunkApi) => {
  const [state, access_token] = getStateAndToken(thunkApi);
  try {
    const { data } = await sendRequest('post', resetPasswordUrl, { email }, token);
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('password.resetPassInstruction'), type: 'success' }));
    return data;
  } catch (error: any) {
    const errorMessage = error.response.data.detail;
    thunkApi.dispatch(setSnackbar({ open: true, message: i18n.t('password.resetPasswordError'), type: 'error' }));
    return thunkApi.rejectWithValue(errorMessage);
  }
});
