import { createAsyncThunk } from '@reduxjs/toolkit';

import { sendRequest } from '../../../store/api';
import { filesUrl, previewPdfUrl } from '../../../settings';
import { arrayBufferToBase64, getStateAndToken } from '../../../utils/helpers';
import { setSnackbar } from '../app/appSlice';

export const getUploadedFiles = createAsyncThunk('file/getUploadedFiles', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);

    const { data } = await sendRequest('get', filesUrl, null, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const handleUploadFile = createAsyncThunk('file/uploadFiles', async ({ files }: { files: File[] }, thunkApi) => {
  const uploadedFiles: any[] = [];
  for (const file of files) {
    try {
      const [state, access_token] = getStateAndToken(thunkApi);

      const formData = new FormData();
      formData.append('file', file);

      const { data } = await sendRequest('post', filesUrl, formData, `Bearer ${access_token}`);
      uploadedFiles.push(data);
      thunkApi.dispatch(getUploadedFiles());
    } catch (error: any) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      thunkApi.dispatch(setSnackbar({ open: true, message: errorMessage, type: 'error' }));
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
  return uploadedFiles;
});

export const deleteUploadedFile = createAsyncThunk(
  'file/removeUploadedFile',
  async ({ id }: { id: number }, thunkApi) => {
    try {
      const [state, access_token] = getStateAndToken(thunkApi);

      const { data } = await sendRequest('delete', `${filesUrl}${id}`, null, `Bearer ${access_token}`);
      thunkApi.dispatch(getUploadedFiles());
      return data;
    } catch (error: any) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      return thunkApi.rejectWithValue(errorMessage);
    }
  },
);

export const handleResetFileUpload = createAsyncThunk('file/resetFileUpload', async (_, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);

    const { uploadedFiles } = state.file;
    let data;
    for (const file of uploadedFiles) {
      await thunkApi.dispatch(deleteUploadedFile({ id: Number(file.id) }));
    }
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getFileInfo = createAsyncThunk('file/getFileInfo', async ({ id }: { id: number }, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);

    const { data } = await sendRequest('get', `${filesUrl}${id}`, null, `Bearer ${access_token}`);
    return data;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});

export const getPdfFiles = createAsyncThunk('file/getPdfFile', async ({ id }: { id: number }, thunkApi) => {
  try {
    const [state, access_token] = getStateAndToken(thunkApi);

    const headers = {
      'Content-Type': 'application/pdf;base64',
    };

    const { data } = await sendRequest(
      'get',
      `${previewPdfUrl}${id}/`,
      undefined,
      `Bearer ${access_token}`,
      'arraybuffer',
    );
    const base64PdfData = arrayBufferToBase64(data);
    return base64PdfData;
  } catch (error: any) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    return thunkApi.rejectWithValue(errorMessage);
  }
});
