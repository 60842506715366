import { setLocationPermission, setSelectedShop, setSnackbar } from '../store/features/app/appSlice';
import { getLocationByIp } from '../components/PrintMap/mapServices';
import { appleLogin, loginGoogle } from '../store/features/user/userServices';
import { getTeam } from '../store/features/team/teamServices';
import { handlePrinterScanCode } from '../store/features/app/appServices';
import { store } from '../store/configureStore';
import { setInstantQouteStep } from '../store/features/order/orderSlice';
import i18n from '../i18nconfig';
export class TypescriptManager {
  qrCodeReceived = (qrCode: any) => {
    let selectedPrinter;
    let status = '';
    if (typeof qrCode === 'string') {
      selectedPrinter = qrCode;
    } else if (qrCode && qrCode.text) {
      selectedPrinter = qrCode.text;
    } else {
      status = i18n.t('messages.errorTryAgain');
      return;
    }

    store.dispatch(handlePrinterScanCode({ selectedPrinter })).then((res) => {
      if (res.meta.requestStatus === 'rejected') {
        status = res?.payload?.message;
        return store.dispatch(setSnackbar({ open: true, message: i18n.t('error.printerNotFound'), type: 'error' }));
      }
      if (res.meta.requestStatus === 'fulfilled') {
        const shopCode = Number(res.payload.location_id);

        const shopsToFilter = store.getState().app?.printers || [];

        const shopInfoById = shopsToFilter.filter((shop) => Number(shop.id) === shopCode);

        if (shopInfoById && store.getState().app?.locationPermission) store.dispatch(setSelectedShop(shopInfoById[0]));
        store.dispatch(setInstantQouteStep(3));
      } else {
        store.dispatch(setSnackbar({ open: true, message: i18n.t('error.invalidQrCode'), type: 'error' }));
      }
    });
  };

  locationPermision(hasPermision: boolean) {
    store.dispatch(setLocationPermission(hasPermision));

    if (hasPermision) {
      getLocationByIp();
    }
  }

  googleLogin = (code: string) => {
    store.dispatch(loginGoogle(code)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        store.dispatch(getTeam());
        window.location.href = '/';
      }
    });
  };

  appleLogin(response: string) {
    store.dispatch(appleLogin(response)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        store.dispatch(getTeam());
        window.location.href = '/';
      }
    });
  }

  render() {
    return null;
  }
}

window.typescriptManager = new TypescriptManager();
