import { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { createGuestUser, getUser } from '../store/features/user/userServices';
import { useAppDispatch, useAppSelector } from '../store/reduxHooks';
import { getShopsLocation } from '../store/features/app/appServices';

export const useRelocation = () => {
  const navigate = useNavigate();
  return (path: string, state?: any) => navigate(path, state);
};

export const useGuestUserInitialization = (userType: string) => {
  const dispatch = useAppDispatch();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const initialize = async () => {
      try {
        const guestUser = Cookies.get('guest_user');
        const guestUserExpiry = Cookies.get('guest_user_expiry');
        const now = new Date();

        if (userType === 'guest' && (!guestUser || (guestUserExpiry && new Date(guestUserExpiry) < now))) {
          const newGuestUser = Math.random().toString(32).substring(2, 15);
          const expiryDate = new Date();
          expiryDate.setDate(expiryDate.getDate() + 14); // Set expiry to 14 days
          Cookies.set('guest_user', newGuestUser, { expires: 14, secure: true, sameSite: 'Strict' });
          Cookies.set('guest_user_expiry', expiryDate.toISOString(), {
            expires: 14,
            secure: true,
            sameSite: 'Strict',
          });
        }

        if (userType === 'guest') {
          const res = await dispatch(createGuestUser());
          if (res.meta.requestStatus === 'fulfilled') {
            await dispatch(getUser());
          }
        }

        if (isMounted) setIsInitialized(true);
      } catch (error) {
        console.error('Error initializing guest user:', error);
      }
    };

    initialize();

    return () => {
      isMounted = false;
    };
  }, [userType, dispatch]);

  return isInitialized;
};
// Table Hooks
export const getTableFilterItems = () => [
  { label: 'Today', name: i18n.t('timeFilter.today'), value: 'today' },
  { name: i18n.t('timeFilter.last7Days'), value: 'last7days' },
  { name: i18n.t('timeFilter.last4Weeks'), value: 'last4weeks' },
  { name: i18n.t('timeFilter.last3Months'), value: 'last3months' },
  { name: i18n.t('timeFilter.last12Months'), value: 'last12months' },
  /*}{ name: i18n.t('timeFilter.monthToDate'), value: 'monthToDate' },
  { name: i18n.t('timeFilter.quarterToDate'), value: 'quarterToDate' },
  { name: i18n.t('timeFilter.yearToDate'), value: 'yearToDate' },*/
  { name: i18n.t('timeFilter.allTime'), value: 'allTime' },
];

export const useUserPrivilege = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const { teamMembers } = useAppSelector((state) => state.team);
  const [userRole, setUserRole] = useState<string | null>(null);

  const getTeamRole = (teamMembers: any, currentUser: any) => {
    const currentUserEmail = currentUser?.email;
    const member = teamMembers.find((member: any) => member.email === currentUserEmail);
    setUserRole(member?.member_type || null);
  };

  useEffect(() => {
    getTeamRole(teamMembers, currentUser);
  }, [teamMembers, currentUser]);

  return userRole;
};

export const useImageWidth = (imagePath: string, imageName: string, isDesktop: boolean) => {
  return useMemo(() => {
    let pic;
    const width = isDesktop ? 2000 : 320;
    try {
      pic = require(`../assets/img/${imagePath}/${width}/${imageName}${width}.webp`);
    } catch (error) {
      try {
        // Fallback to PNG if the WebP image is not available
        pic = require(`../assets/img/${imagePath}/${width}/${imageName}${width}.png`);
      } catch (error) {
        // Fallback to SVG if the PNG image is not available
        pic = require(`../assets/img/${imagePath}/${width}/${imageName}${width}.svg`);
      }
    }

    return [{ pic, width }];
  }, [imagePath, imageName, isDesktop]);
};

export const usePreloadImages = (imageUrls: string[]) => {
  useEffect(() => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  }, [imageUrls]);
};

export default usePreloadImages;

export const useImageForWidth = (images: { pic: string; width: number }[], width: number) => {
  return useMemo(() => {
    const sortedImages = [...images].sort((a, b) => b.width - a.width);
    const appropriateImage = sortedImages.find((image) => image.width <= width);
    return appropriateImage ? `${appropriateImage.pic}?format=webp` : `${sortedImages[0].pic}?format=png`;
  }, [images, width]);
};

export const useFormatFileName = () => {
  return useCallback((fileName: string, maxLength: number) => {
    const prefixLength = maxLength; // number of characters to show at the start
    const suffixLength = 3; // number of characters to show at the end
    const ellipsis = '...';

    if (fileName.length <= prefixLength + suffixLength) {
      return fileName;
    }

    const prefix = fileName.slice(0, prefixLength);
    const suffix = fileName.slice(-suffixLength);

    return `${prefix}${ellipsis}${suffix}`;
  }, []);
};
